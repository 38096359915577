import React from 'react'
import { Link } from 'gatsby'

export default function Button(props) {
    return (
        <Link    
        aria-label={props.link}
        to={props.href} 
        className={`inline-flex p-4 rounded-md duration-300 justify-center 
            ${props.orange ? `border text-white border-orange-500 bg-orange-500 hover:bg-white hover:text-orange-500 ${props.borderHover ? 'hover:border-orange-300' : 'hover:border-white'}` : ''} 
            ${props.white ? 'border bg-white border-white text-green-700 hover:bg-orange-700 hover:border-orange-700 hover:text-white' : ''}
            ${props.ghost ? `border bg-none text-green-700 border-green-700 hover:text-white hover:bg-green-700` : ''}`}
        >
            {props.link}
        </Link>
    )
}