import React from 'react'
import ReactMarkdown from 'react-markdown'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Container, Row, Col } from 'react-grid-system'
import Carousel from 'react-elastic-carousel'

import DataTable from "../../elements/table"
import Button from '../../elements/button'
import 'react-lazy-load-image-component/src/effects/blur.css'

export default function ImgSection(props) {

    return (
        <div className={`${props.bgPurple ? "bg-purple-300" : "bg-green-300"} ${props.white ? 'py-20 md:py-56 bg-none' : 'shadow-xl pt-20'}`}>
            <Container className="img-section">
                <Row className={`flex ${props.reverse ? 'flex-col-reverse md:flex-row-reverse' : ''}`}>
                    <Col md={1}>&nbsp;</Col>
                    <Col md={5} className="imgsection-description pb-20 inline-flex flex-col justify-center items-baseline">
                        <h4 className={`w-full ${props.white ? 'text-orange-300' : 'text-white' }`}>{props.subtitle}</h4>
                        <h3 className={`w-full ${props.white ? 'text-green-300' : 'text-orange-300' }`}>{props.title}</h3>
                        <ReactMarkdown children={props.description} className={`mb-10 ${props.white ? null : 'white-text'}`} />
                        {props.link ?
                            props.buttonOrange ? 
                            <Button orange link={props.link} href={props.href} />
                            :  
                            <Button white link={props.link} href={props.href} />
                        : null }
                    </Col>
                    <Col md={6} className={`${props.white ? null : "imgsection-image" }`}>
                        {!props.video ? //
                            !props.tableHeaders ? // <== If table isn't hidden, check for slideshow. 
                                !props.slideshow ? // <== If no slideshow, show image. Else, slideshow.
                                <LazyLoadImage
                                    alt={props.link}
                                    src={props.image}
                                    className="shadow-md rounded-lg"
                                    effect="blur"
                                />
                                :
                                <div className="kiln-carousel">
                                    <Carousel 
                                        itemsToShow={1}
                                        showArrows={false}
                                        autoPlaySpeed={8000}
                                        enableAutoPlay={true}
                                    >
                                        {props.slideshow.map((e, i) => (
                                        <div key={i}>
                                            <LazyLoadImage
                                                src={e.image}
                                                alt="Image Slide"
                                                className="shadow-md rounded-lg"
                                                effect="blur"
                                            />
                                        </div>
                                        ))}
                                    </Carousel>                                    
                                </div>             
                                : 
                                <DataTable
                                    headers={props.tableHeaders} 
                                    rows={props.tableRows} 
                                />
                            : 
                            <a href="https://www.youtube.com/watch?v=BMQ3UP1lenA" target="_blank" rel="noopener noreferrer">
                                <LazyLoadImage
                                    alt={props.link}
                                    src={props.image}
                                    className="shadow-md rounded-lg"
                                    effect="blur"
                                />
                            </a>

                        }
                    </Col>
                </Row>   
            </Container>
        </div>
    )
}