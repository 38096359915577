import React from "react"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

export default function DataTable(props) {

  return (
    <Table>
      <Thead>
        <Tr className="header-row">
            {props.headers.map((h, i) => (
                <Th key={i}>
                    {h.header}
                </Th>
            ))}
        </Tr>
      </Thead>
      <Tbody>
        {props.rows.map((r, i) => (
            <Tr key={i}>
                <Td>{r.model}</Td>
                <Td>{r.drying_capacity}</Td>
            </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
